export const DB_CONFIG_RECIPE = {
  apiKey: "AIzaSyCJxyh3toDi7A_cBTEUOmunwcQR3LGzZe0",
  authDomain: "cookbook-9df35.firebaseapp.com",
  databaseURL: "https://cookbook-9df35.firebaseio.com",
  projectId: "cookbook-9df35",
  storageBucket: "cookbook-9df35.appspot.com",
  messagingSenderId: "414492008595",
  appId: "1:414492008595:web:ecf20c8341b9d0e2bcad66",
  measurementId: "G-H1LNE6RMD6"
};
