export const DB_CONFIG = {
  apiKey: "AIzaSyA38kk4PMO8DvOFWqATTlHkeElv95eMI2E",
  authDomain: "flashcards-10fca.firebaseapp.com",
  databaseURL: "https://flashcards-10fca.firebaseio.com",
  projectId: "flashcards-10fca",
  storageBucket: "flashcards-10fca.appspot.com",
  messagingSenderId: "372472188286",
  //   dunno if I need this below lul
  appId: "1:372472188286:web:a4b464d57c2c87c62d772f",
  measurementId: "G-P2QFZQ48GP"
};
