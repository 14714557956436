export const DB_CONFIG = {
  apiKey: "AIzaSyAJ_FnOo3QqOjZ5cY8NDiqBaq3xw51TePw",
  authDomain: "general-database-95847.firebaseapp.com",
  databaseURL: "https://general-database-95847.firebaseio.com",
  projectId: "general-database-95847",
  storageBucket: "general-database-95847.appspot.com",
  messagingSenderId: "807273003668",
  appId: "1:807273003668:web:2bb20bd0826d4538bed281",
  measurementId: "G-0DLW9K7EN0"
};
